import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["open"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "QAM-modal" }
const _hoisted_4 = { style: {"grid-area":"title","position":"relative","padding":"1em","padding-bottom":"0"} }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { style: {"text-align":"center","margin":"0","font-size":"1em"} }
const _hoisted_8 = {
  class: "QAM-filter-container my-5",
  style: {"grid-area":"input"}
}
const _hoisted_9 = { class: "sr-only visually-hidden" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  class: "QAM-column QAM-sidebar",
  style: {"grid-area":"side"}
}
const _hoisted_13 = { class: "QAM-scrollzone" }
const _hoisted_14 = {
  class: "QAM-column",
  style: {"grid-area":"global"}
}
const _hoisted_15 = {
  class: "QAM-scrollzone",
  ref: "globalResults"
}
const _hoisted_16 = { class: "sr-only visually-hidden" }
const _hoisted_17 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_quick_access_menu_item = _resolveComponent("quick-access-menu-item")
  const _component_quick_access_menu_global_search = _resolveComponent("quick-access-menu-global-search")

  return (_openBlock(), _createElementBlock("dialog", {
    open: $data.isVisible ? 'open' : null,
    class: _normalizeClass($options.classObject),
    style: _normalizeStyle($options.styleObject),
    onKeydown: _cache[7] || (_cache[7] = (event) => event.key === 'Escape' && $options.hide())
  }, [
    _createElementVNode("div", {
      class: "QAM-close-backdrop",
      onClick: _cache[0] || (_cache[0] = (...args) => ($options.hide && $options.hide(...args))),
      "aria-label": _ctx.__('Close')
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("header", _hoisted_4, [
        (_ctx.frappe.get_route() && _ctx.frappe.get_route().length && _ctx.frappe.get_route()[0])
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "/app",
              style: {"position":"absolute"},
              "aria-label": _ctx.__('Home'),
              onClick: _cache[1] || (_cache[1] = $event => ($options.hide()))
            }, [
              _createElementVNode("img", {
                style: {"width":"24px"},
                src: _ctx.frappe.boot.app_logo_url,
                alt: "__('App Logo')"
              }, null, 8 /* PROPS */, _hoisted_6)
            ], 8 /* PROPS */, _hoisted_5))
          : _createCommentVNode("v-if", true),
        _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.__("Quick Access")), 1 /* TEXT */)
      ]),
      _createElementVNode("label", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.__("Search")), 1 /* TEXT */),
        _withDirectives(_createElementVNode("input", {
          ref: "input",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.searchQuery) = $event)),
          type: "search",
          class: "form-control",
          placeholder: _ctx.__('Search') + '...',
          autocomplete: "off",
          onKeyup: _cache[3] || (_cache[3] = (event) => event.key === 'Enter' && $options.onEnter()),
          onKeydown: _cache[4] || (_cache[4] = (...args) => ($options.inputOnKeyDown && $options.inputOnKeyDown(...args)))
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_10), [
          [_vModelText, $data.searchQuery]
        ]),
        _createElementVNode("span", {
          class: "search-icon",
          innerHTML: _ctx.frappe.utils.icon('search', 'md')
        }, null, 8 /* PROPS */, _hoisted_11)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createCommentVNode(" <h3 class=\"QAM-title\" style=\"font-size: 1em; text-align: center;\">{{ __('Workspace') }}</h3> "),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("ul", {
            class: "QAM-results",
            ref: "workspaceResults",
            onKeydown: _cache[5] || (_cache[5] = (...args) => ($options.listOnKeyDown && $options.listOnKeyDown(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_quick_access_menu_item, {
                key: index,
                item: item
              }, null, 8 /* PROPS */, ["item"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 544 /* HYDRATE_EVENTS, NEED_PATCH */)
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_quick_access_menu_global_search, { value: $data.searchQuery }, null, 8 /* PROPS */, ["value"])
        ], 512 /* NEED_PATCH */)
      ]),
      _createElementVNode("button", {
        class: "QAM-close-button",
        onClick: _cache[6] || (_cache[6] = (...args) => ($options.hide && $options.hide(...args)))
      }, [
        _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.__("Close")), 1 /* TEXT */),
        _createElementVNode("span", {
          innerHTML: _ctx.frappe.utils.icon('close', 'lg')
        }, null, 8 /* PROPS */, _hoisted_17)
      ])
    ])
  ], 46 /* CLASS, STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_1))
}