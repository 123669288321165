import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "QuickAccessMenuItem" }
const _hoisted_2 = ["item-icon", "innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "QAMI-label"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.href ? 'a' : 'button'), {
      class: "QAMI-box",
      tabindex: "0",
      href: $setup.href,
      title: _ctx.__($props.item.title),
      onClick: _cache[0] || (_cache[0] = $event => ($setup.onSelect())),
      style: _normalizeStyle({ '--item-color': $props.item.color })
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: "QAMI-icon",
          "item-icon": $props.item.icon || 'folder-normal',
          style: _normalizeStyle($props.item.color && '--icon-fill:' + $props.item.color),
          innerHTML: _ctx.frappe.utils.icon($props.item.icon || 'folder-normal', 'md')
        }, null, 12 /* STYLE, PROPS */, _hoisted_2),
        ($props.item._html)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "QAMI-label",
              innerHTML: $props.item._html
            }, null, 8 /* PROPS */, _hoisted_3))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.__($setup.label)), 1 /* TEXT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["href", "title", "style"]))
  ]))
}