import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "QAMGlobalSearchResults",
  role: "search",
  onsubmit: "return false;"
}
const _hoisted_2 = { ref: "wrapperElement" }
const _hoisted_3 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */),
    _createElementVNode("input", {
      value: $props.value,
      type: "search",
      style: {"display":"none"},
      disabled: "",
      ref: "inputElement"
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}