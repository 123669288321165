// https://github.com/farzher/fuzzysort v2.0.0
((r, e) => {
	"function" == typeof define && define.t
		? define([], e)
		: "object" == typeof module && module.exports
		? (module.exports = e())
		: (r.fuzzysort = e());
})(this, (r) => {
	var i,
		o,
		e,
		a,
		f = (r) => {
			var e = v((r = "string" != typeof r ? "" : r));
			return { target: r, i: e.o, v: e.u, l: N, g: e._, score: N, p: [0], obj: N };
		},
		n = (r) => {
			r = (r = "string" != typeof r ? "" : r).trim();
			var e = v(r),
				a = [];
			if (e.h)
				for (var f, n = r.split(/\s+/), n = [...new Set(n)], t = 0; t < n.length; t++)
					"" !== n[t] &&
						((f = v(n[t])), a.push({ u: f.u, o: n[t].toLowerCase(), h: !1 }));
			return { u: e.u, _: e._, h: e.h, o: e.o, j: a };
		},
		M = (r) => {
			if (999 < r.length) return f(r);
			var e = t.get(r);
			return void 0 !== e || ((e = f(r)), t.set(r, e)), e;
		},
		q = (r) => {
			if (999 < r.length) return n(r);
			var e = s.get(r);
			return void 0 !== e || ((e = n(r)), s.set(r, e)), e;
		},
		D = (r, e) => {
			if (r.h) return j(r, e);
			for (
				var a = r.o,
					f = r.u,
					n = f[0],
					t = e.v,
					i = f.length,
					o = t.length,
					v = 0,
					s = 0,
					u = 0;
				;

			) {
				if (n === t[s]) {
					if (((C[u++] = s), ++v === i)) break;
					n = f[v];
				}
				if (o <= ++s) return N;
			}
			var v = 0,
				l = !1,
				g = 0,
				d = e.l,
				c = (d === N && (d = e.l = k(e.target)), (s = 0 === C[0] ? 0 : d[C[0] - 1]), 0);
			if (s !== o)
				for (;;)
					if (o <= s) {
						if (v <= 0) break;
						if (200 < ++c) break;
						--v;
						s = d[L[--g]];
					} else if (f[v] === t[s]) {
						if (((L[g++] = s), ++v === i)) {
							l = !0;
							break;
						}
						++s;
					} else s = d[s];
			var w = e.i.indexOf(a, C[0]),
				r = ~w;
			if (r && !l) for (var _ = 0; _ < u; ++_) C[_] = w + _;
			a = !1;
			r && (a = e.l[w - 1] === w);
			p = l ? ((b = L), g) : ((b = C), u);
			for (var b, p, x = 0, h = 0, _ = 1; _ < i; ++_)
				b[_] - b[_ - 1] != 1 && ((x -= b[_]), ++h);
			if (
				((x -= (12 + (b[i - 1] - b[0] - (i - 1))) * h), 0 !== b[0] && (x -= 10 * b[0]), l)
			) {
				for (var y = 1, _ = d[0]; _ < o; _ = d[_]) ++y;
				24 < y && (x *= 10 * (y - 24));
			} else x *= 1e3;
			r && (x /= 10), a && (x /= 10), (e.score = x -= o - i);
			for (_ = 0; _ < p; ++_) e.p[_] = b[_];
			return (e.p.k = p), e;
		},
		j = (r, e) => {
			for (var a = new Set(), f = 0, n = N, t = 0, i = r.j, o = 0; o < i.length; ++o) {
				var v = i[o];
				if ((n = D(v, e)) === N) return N;
				(f += n.score), n.p[0] < t && (f -= t - n.p[0]);
				for (var t = n.p[0], s = 0; s < n.p.k; ++s) a.add(n.p[s]);
			}
			n.score = f;
			var u,
				o = 0;
			for (u of a) n.p[o++] = u;
			return (n.p.k = o), n;
		},
		v = (r) => {
			for (var e = r.length, a = r.toLowerCase(), f = [], n = 0, t = !1, i = 0; i < e; ++i) {
				var o = (f[i] = a.charCodeAt(i));
				32 === o
					? (t = !0)
					: (n |=
							1 <<
							(97 <= o && o <= 122
								? o - 97
								: 48 <= o && o <= 57
								? 26
								: o <= 127
								? 30
								: 31));
			}
			return { u: f, _: n, h: t, o: a };
		},
		k = (r) => {
			for (
				var e = r.length,
					a = ((r) => {
						for (var e = r.length, a = [], f = 0, n = !1, t = !1, i = 0; i < e; ++i) {
							var o = r.charCodeAt(i),
								v = 65 <= o && o <= 90,
								o = v || (97 <= o && o <= 122) || (48 <= o && o <= 57),
								s = (v && !n) || !t || !o,
								n = v,
								t = o;
							s && (a[f++] = i);
						}
						return a;
					})(r),
					f = [],
					n = a[0],
					t = 0,
					i = 0;
				i < e;
				++i
			)
				i < n ? (f[i] = n) : ((n = a[++t]), (f[i] = void 0 === n ? e : n));
			return f;
		},
		t = new Map(),
		s = new Map(),
		C = [],
		L = [],
		E = (r) => {
			for (var e = J, a = r.length, f = 0; f < a; ++f) {
				var n = r[f];
				n !== N && e < (n = n.score) && (e = n);
			}
			return e === J ? N : e;
		},
		F = (r, e) => {
			var a = r[e];
			if (void 0 !== a) return a;
			for (
				var f = e, n = (f = Array.isArray(e) ? f : e.split(".")).length, t = -1;
				r && ++t < n;

			)
				r = r[f[t]];
			return r;
		},
		G = (r) => "object" == typeof r,
		H = 1 / 0,
		J = -H,
		K = [],
		N = ((K.total = 0), null),
		O =
			((i = []),
			(o = 0),
			(a = (r) => {
				for (var e = i[(n = 0)], a = 1; a < o; ) {
					var f = a + 1,
						n = a;
					f < o && i[f].score < i[a].score && (n = f),
						(i[(n - 1) >> 1] = i[n]),
						(a = 1 + (n << 1));
				}
				for (var t = (n - 1) >> 1; 0 < n && e.score < i[t].score; t = ((n = t) - 1) >> 1)
					i[n] = i[t];
				i[n] = e;
			}),
			((e = {}).add = (r) => {
				var e = o;
				i[o++] = r;
				for (var a = (e - 1) >> 1; 0 < e && r.score < i[a].score; a = ((e = a) - 1) >> 1)
					i[e] = i[a];
				i[e] = r;
			}),
			(e.C = (r) => {
				var e;
				if (0 !== o) return (e = i[0]), (i[0] = i[--o]), a(), e;
			}),
			(e.L = (r) => {
				if (0 !== o) return i[0];
			}),
			(e.S = (r) => {
				(i[0] = r), a();
			}),
			e);
	return {
		single: (r, e) => {
			if ("farzher" == r) return { target: "farzher was here (^-^*)/", score: 0, p: [0] };
			if (!r || !e) return N;
			var r = q(r),
				a = (G(e) || (e = M(e)), r._);
			return (a & e.g) !== a ? N : D(r, e);
		},
		go: (r, e, a) => {
			if ("farzher" == r)
				return [
					{ target: "farzher was here (^-^*)/", score: 0, p: [0], obj: e ? e[0] : N },
				];
			if (!r)
				if (a && a.all) {
					var f = e;
					var n = a;
					var t = [],
						i = ((t.total = f.length), (n && n.limit) || H);
					if (n && n.key)
						for (var o = 0; o < f.length; o++) {
							var v = f[o];
							var s = F(v, n.key);
							if (!s) continue;
							if (!G(s)) s = M(s);
							s.score = J;
							s.p.k = 0;
							var u = s;
							u = {
								target: u.target,
								i: "",
								v: N,
								l: N,
								g: 0,
								score: s.score,
								p: N,
								obj: v,
							};
							t.push(u);
							if (t.length >= i) return t;
						}
					else if (n && n.keys)
						for (o = 0; o < f.length; o++) {
							v = f[o];
							var l = new Array(n.keys.length);
							for (var g = n.keys.length - 1; g >= 0; --g) {
								s = F(v, n.keys[g]);
								if (!s) {
									l[g] = N;
									continue;
								}
								if (!G(s)) s = M(s);
								s.score = J;
								s.p.k = 0;
								l[g] = s;
							}
							l.obj = v;
							l.score = J;
							t.push(l);
							if (t.length >= i) return t;
						}
					else
						for (o = 0; o < f.length; o++) {
							s = f[o];
							if (!s) continue;
							if (!G(s)) s = M(s);
							s.score = J;
							s.p.k = 0;
							t.push(s);
							if (t.length >= i) return t;
						}
					return t;
				} else return K;
			var d = q(r),
				c = d._,
				w = (d.h, (a && a.threshold) || J),
				_ = (a && a.limit) || H,
				b = 0,
				p = 0,
				x = e.length;
			if (a && a.key)
				for (var h = a.key, y = 0; y < x; ++y) {
					var j = e[y];
					!(m = F(j, h)) ||
						(c & (m = G(m) ? m : M(m)).g) !== c ||
						(B = D(d, m)) === N ||
						B.score < w ||
						((B = {
							target: B.target,
							i: "",
							v: N,
							l: N,
							g: 0,
							score: B.score,
							p: B.p,
							obj: j,
						}),
						b < _ ? (O.add(B), ++b) : (++p, B.score > O.L().score && O.S(B)));
				}
			else if (a && a.keys)
				for (var k = a.scoreFn || E, C = a.keys, L = C.length, y = 0; y < x; ++y) {
					for (var j = e[y], S = new Array(L), z = 0; z < L; ++z) {
						h = C[z];
						(m = F(j, h))
							? (c & (m = G(m) ? m : M(m)).g) !== c
								? (S[z] = N)
								: (S[z] = D(d, m))
							: (S[z] = N);
					}
					S.obj = j;
					var A = k(S);
					A === N ||
						A < w ||
						((S.score = A),
						b < _ ? (O.add(S), ++b) : (++p, A > O.L().score && O.S(S)));
				}
			else
				for (var m, B, y = 0; y < x; ++y)
					!(m = e[y]) ||
						(c & (m = G(m) ? m : M(m)).g) !== c ||
						(B = D(d, m)) === N ||
						B.score < w ||
						(b < _ ? (O.add(B), ++b) : (++p, B.score > O.L().score && O.S(B)));
			if (0 === b) return K;
			for (var I = new Array(b), y = b - 1; 0 <= y; --y) I[y] = O.C();
			return (I.total = b + p), I;
		},
		highlight: (r, e, a) => {
			if ("function" == typeof e) {
				var f = e;
				if ((l = r) === N) return N;
				for (
					var n = l.target,
						t = n.length,
						i = (i = l.p).slice(0, i.k).sort((r, e) => r - e),
						o = "",
						v = 0,
						s = 0,
						u = !1,
						l = [],
						g = 0;
					g < t;
					++g
				) {
					var d = n[g];
					if (i[s] === g) {
						if ((++s, u || ((u = !0), l.push(o), (o = "")), s === i.length)) {
							(o += d), l.push(f(o, v++)), (o = ""), l.push(n.substr(g + 1));
							break;
						}
					} else u && ((u = !1), l.push(f(o, v++)), (o = ""));
					o += d;
				}
				return l;
			}
			if (r === N) return N;
			void 0 === e && (e = "<b>"), void 0 === a && (a = "</b>");
			for (
				var c = "",
					w = 0,
					_ = !1,
					b = r.target,
					p = b.length,
					x = (x = r.p).slice(0, x.k).sort((r, e) => r - e),
					h = 0;
				h < p;
				++h
			) {
				var y = b[h];
				if (x[w] === h) {
					if ((_ || ((_ = !0), (c += e)), ++w === x.length)) {
						c += y + a + b.substr(h + 1);
						break;
					}
				} else _ && ((_ = !1), (c += a));
				c += y;
			}
			return c;
		},
		prepare: f,
		indexes: (r) => r.p.slice(0, r.p.k).sort((r, e) => r - e),
		cleanup: () => {
			t.clear(), s.clear(), (C = []), (L = []);
		},
	};
});
